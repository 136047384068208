/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { clone } from '../components/Utils';
import { ImageEnvRequirementTable, ImageEnvRequirementType } from '../components/tables';
import EnvField from '../components/EnvField';

interface EnvsDialogProps {
    envs: { key: string, value: string }[],
    imageEnvs: ImageEnvRequirementTable[],
    containerId: string,
    refetch: () => void
}

const EnvsDialog:React.FC<EnvsDialogProps> = props => {
    const [open, setOpen] = useState(false);
    const [envs, setEnvs] = useState<{
        key: string,
        value: string
    }[]>([]);
    const [containers, setContainers] = useState([]);
    const [selectedContainer, setSelectedContainer] = useState('');

    const client = useApolloClient();

    useEffect(
        () => {
            setEnvs(props.envs || []);
            client.query({
                query: gql`
                    query {
                        getContainers {
                            id
                            name
                            envs {
                                key
                                value
                            }
                        }
                    }
                `,
            }).then(result => {
                setContainers(result.data.getContainers);
            });
        },
        [open],
    );
    const [changeContainerEnvs] = useMutation(gql`
        mutation($id: String!, $envs: [ContainerParameterInput]) {
            changeContainerEnvs(id: $id envs: $envs)
        }
    `);

    return <>
        <Button onClick={() => setOpen(true)}>Change</Button>
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
            <DialogTitle>Envs</DialogTitle>
            <DialogContent>
                <div>
                    <Select value={selectedContainer} onChange={e => setSelectedContainer(e.target.value)}>
                        {containers.map(container => <MenuItem key={container.id} value={container.id}>{container.name}</MenuItem>)}
                    </Select>
                    <Button onClick={() => {
                        const container = containers.find(_container => _container.id === selectedContainer);
                        setEnvs(container.envs);
                    }}
                    >
                        Copy from container
                    </Button>
                </div>
                {envs.map((env, i) => {
                    const imageEvns = props.imageEnvs.find(imageEnv => imageEnv.env_name === env.key);
                    return <div key={i} style={{ display: 'flex' }}>
                        <TextField
                            label="Name"
                            value={env.key}
                            onChange={e => {
                                const newEnvs = clone(envs);
                                newEnvs[i].key = e.target.value;
                                setEnvs(newEnvs);
                            }}
                            fullWidth
                            variant="standard"
                        />
                        {imageEvns ? '*' : ''}
                        <EnvField
                            onChange={value => {
                                const newEnvs = clone(envs);
                                newEnvs[i].value = value;
                                setEnvs(newEnvs);
                            }}
                            type={imageEvns?.type || ImageEnvRequirementType.String}
                            value={env.value}
                            label="Value"
                        />
                        <IconButton onClick={() => {
                            const newEnvs = clone(envs);
                            newEnvs.splice(i, 1);
                            setEnvs(newEnvs);
                        }}
                        >
                            <Delete />
                        </IconButton>
                    </div>;
                })}
                {props.imageEnvs.filter(imageEnv => !envs.find(env => env.key === imageEnv.env_name)).map((imageEnv, i) =>
                    <div key={`image${i}`}>
                        <Button
                            onClick={() => {
                                const newEnvs = clone(envs);
                                newEnvs.push({ key: imageEnv.env_name, value: imageEnv.env_default_value });
                                setEnvs(newEnvs);
                            }}
                            variant="contained"
                        >
                        Add env
                            {' '}
                            {imageEnv.env_name}
                        </Button>
                    </div>)}
                <Button onClick={() => {
                    const newEnvs = clone(envs);
                    newEnvs.push({ key: '', value: '' });
                    setEnvs(newEnvs);
                }}
                >
Add
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={async () => {
                    await changeContainerEnvs({
                        variables: {
                            id: props.containerId,
                            envs: envs.map(env => {
                                delete (env as any).__typename;
                                return env;
                            }),
                        },
                    });
                    props.refetch();
                    setOpen(false);
                }}
                >
OK
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default EnvsDialog;
