import React from 'react';
import { Checkbox, IconButton, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ImageEnvRequirementType } from './tables';

interface EnvFieldProps {
    value: string,
    label: string,
    type: ImageEnvRequirementType,
    onChange: (value: string) => void
}

const EnvField: React.FC<EnvFieldProps> = props => {
    const [show, setShow] = React.useState(false);
    return <>
        {props.type === ImageEnvRequirementType.String && <TextField
            label={props.label}
            value={props.value}
            onChange={e => {
                props.onChange(e.target.value);
            }}
            fullWidth
            variant="standard"
        />}
        {
            props.type === ImageEnvRequirementType.Number && <TextField
                label={props.label}
                value={props.value}
                onChange={e => {
                    props.onChange(e.target.value);
                }}
                fullWidth
                variant="standard"
                type="number"
            />
        }
        {
            props.type === ImageEnvRequirementType.Boolean && <Checkbox
                checked={!!props.value}
                onChange={e => {
                    props.onChange(e.target.checked ? '1' : '');
                }}
            />
        }
        {props.type === ImageEnvRequirementType.Password && <>
            <TextField
                label={props.label}
                value={props.value}
                onChange={e => {
                    props.onChange(e.target.value);
                }}
                fullWidth
                variant="standard"
                type={show ? 'text' : 'password'}
            />
            <IconButton onClick={() => setShow(!show)}>{show ? <VisibilityOff /> : <Visibility />}</IconButton>
        </>}
    </>;
};

export default EnvField;
