import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import {
    Button, IconButton, MenuItem, TextField,
} from '@mui/material';
import moment from 'moment';
import { Refresh } from '@mui/icons-material';
import Id from '../../components/Id';
import Log from './Log';

const Logs = props => {
    const { data, loading, refetch } = useQuery(gql`
        query {
            getLogs {
                id
                name
                create_date
                close_date
                is_closed
            }
        }
      `);

    const [removeLog] = useMutation(gql`
        mutation($id: String!) {
            removeLog(id: $id)
        }
    `);

    const [filter, setFilter] = useState('');

    if (loading) return <div>Loading...</div>;

    return <div>
        <h2>
Logs
            <IconButton onClick={() => refetch()}><Refresh /></IconButton>
        </h2>
        <div>
            <TextField
                fullWidth
                label="Search"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                variant="standard"
            />
        </div>
        <table>
            <thead>
                <tr>
                    <th>id</th>
                    <th>create date</th>
                    <th>status</th>
                    <th>name</th>
                </tr>
            </thead>
            <tbody>
                {data.getLogs
                    .filter(log => (filter ? log.name.includes(filter) : true))
                    .map(log => <tr key={log.id}>
                        <td><Id id={log.id} /></td>
                        <td>{moment(log.create_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td>{log.is_closed ? `Closed at ${moment(log.close_date).format('YYYY-MM-DD HH:mm:ss')}` : 'Working'}</td>
                        <td>
                            <MenuItem
                                onClick={() => props.addWindows('Log', Log, { id: log.id })}
                            >
                                {log.name}
                            </MenuItem>
                        </td>
                        <td>
                            <Button onClick={async () => {
                                props.confirmDialog({
                                    title: 'Remove log',
                                    message: 'Are you sure you want to remove this log?',
                                    onConfirm: async () => {
                                        await removeLog({ variables: { id: log.id } });
                                        refetch();
                                    },
                                });
                            }}
                            >
                            Remove
                            </Button>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </div>;
};

export default Logs;
