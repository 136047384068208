/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import Id from '../../components/Id';
import DbDialog from '../../dialogs/DbDialog';
import DomainDialog from '../../dialogs/DomainDialog';
import CertificateDialog from '../../dialogs/CertificateDialog';
import BuilderDialog from '../../dialogs/BuilderDialog';
import RepositoryDialog from '../../dialogs/RepositoryDialog';
import EnvsDialog from '../../dialogs/EnvsDialog';
import ContainerLog from '../containers/ContainerLog';
import EditInstance from './EditInstance';
import EditContainer from '../containers/EditContainer';
import ContainerVolumes from '../containers/ContainerVolumes';
import AddContainer from '../containers/AddContainer';
import ContainerExplorer from '../containers/ContainerExplorer';
import AddContainerWizard from '../containers/AddContainerWizard';

const DeviceFormNew = props => {
    const [dialog, setDialog] = useState(false);
    return <>
        {!props.update && props.device.name}
        <Button onClick={() => setDialog(true)}>{props.update ? 'Edit device' : 'Add device'}</Button>
        {props.device.device_type_id === 'db' && <DbDialog
            open={dialog}
            onClose={() => setDialog(false)}
            containerId={props.container_id}
            deviceId={props.device.id}
            update={props.update}
        />}
        {props.device.device_type_id === 'domain' && <DomainDialog
            open={dialog}
            onClose={() => setDialog(false)}
            containerId={props.container_id}
            deviceId={props.device.id}
            update={props.update}
        />}
        {props.device.device_type_id === 'certificate' && <CertificateDialog
            open={dialog}
            onClose={() => setDialog(false)}
            containerId={props.container_id}
            deviceId={props.device.id}
            update={props.update}
        />}
        {props.device.device_type_id === 'builder' && <BuilderDialog
            open={dialog}
            onClose={() => setDialog(false)}
            containerId={props.container_id}
            deviceId={props.device.id}
            update={props.update}
        />}
        {props.device.device_type_id === 'repository' && <RepositoryDialog
            open={dialog}
            onClose={() => setDialog(false)}
            containerId={props.container_id}
            deviceId={props.device.id}
            update={props.update}
        />}
    </>;
};

const Instance = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query($id: String!) {
            getAppInstance(id: $id) {
                id
                app_id
                user_id
                life_status
                name
                containers {
                    id
                    docker_runtime_id
                    name
                    life_status
                    docker_status
                    outer_port
                    devices {
                        device {
                            id
                            name
                        }
                        env_parameters {
                            key
                            value
                        }
                        # parameters {
                        #     key
                        #     value
                        # }
                    }
                    volumes {
                        id
                        volume_id
                        name
                        inner_path
                    }
                    envs {
                        key
                        value
                    }
                    domain {
                        id
                        name
                    }
                    node {
                        host
                        name
                    }
                    image {
                        id
                        name
                        envs {
                            id
                            type
                            env_name
                            env_default_value
                        }
                    }
                }
            }
            getApps {
                id
                name
            }
            getVolumes {
                id
                name
                type
                outer_path
            }
            getDevices {
                id
                name
                device_type_id
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [startInstance] = useMutation(gql`
        mutation($id: String!) {
            startAppInstance(id: $id)
        }
    `);
    const [stopInstance] = useMutation(gql`
        mutation($id: String!) {
            stopAppInstance(id: $id)
        }
    `);
    const [restartInstance] = useMutation(gql`
        mutation($id: String!) {
            restartAppInstance(id: $id)
        }
    `);
    const [removeInstance] = useMutation(gql`
        mutation($id: String!) {
            removeAppInstance(id: $id)
        }
    `);
    const [updateContainer] = useMutation(gql`
        mutation($id: String! $noRebuild: Boolean) {
            updateContainer(id: $id noRebuild: $noRebuild)
        }
    `);
    const [removeDevice] = useMutation(gql`
        mutation($containerId: String, $deviceId: String) {
            removeDeviceFromContainer(container_id: $containerId device_id: $deviceId)
        }
    `);
    const [buildInstance] = useMutation(gql`
        mutation($id: String!) {
            buildAppInstance(id: $id)
        }
    `);
    const [stopContainer] = useMutation(gql`
        mutation($id: String!) {
            stopContainer(id: $id)
        }
    `);
    const [startContainer] = useMutation(gql`
        mutation($id: String!) {
            startContainer(id: $id)
        }
    `);
    const [removeContainer] = useMutation(gql`
        mutation($id: String!) {
            removeContainer(id: $id)
        }
    `);
    if (loading) return 'Loading...';
    props.setTitle(`Instance ${data.getAppInstance.name}`);
    const instance = data.getAppInstance;
    const app = data.getApps.find(_app => _app.id === instance.app_id);
    return (
        <>
            <h2>
Instance
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            {app?.name}
            {': '}
            {instance.name}
            <div>
                <Button
                    variant="contained"
                    onClick={() => {
                        props.addWindows('Edit instance', EditInstance, { instance, refetch });
                    }}
                >
Edit instance
                </Button>
            </div>
            <table>
                <tr>
                    <th>id</th>
                    <td><Id id={instance.id} /></td>
                </tr>
                <tr>
                    <th>app</th>
                    <td><Id id={instance.app_id} /></td>
                </tr>
                <tr>
                    <th>user</th>
                    <td><Id id={instance.user_id} /></td>
                </tr>
                <tr>
                    <th>life status</th>
                    <td>{instance.life_status}</td>
                </tr>
            </table>
            <h4>Actions</h4>
            <div>
                {instance.life_status === 'running' && <Button
                    onClick={async () => {
                        await stopInstance({ variables: { id: instance.id } });
                        refetch();
                    }}
                >
Stop
                </Button>}
                {instance.life_status === 'stopped' && <Button
                    onClick={async () => {
                        await startInstance({ variables: { id: instance.id } });
                        refetch();
                    }}
                >
Start
                </Button>}
                <Button
                    onClick={async () => {
                        await restartInstance({ variables: { id: instance.id } });
                        refetch();
                    }}
                >
Restart
                </Button>
                <Button
                    onClick={async () => {
                        await buildInstance({ variables: { id: instance.id } });
                        refetch();
                    }}
                >
Build
                </Button>
                <Button
                    onClick={async () => {
                        props.confirmDialog({
                            title: 'Remove instance',
                            message: `Are you sure you want to remove instance ${instance.name}?`,
                            onConfirm: async () => {
                                await removeInstance({ variables: { id: instance.id } });
                                refetch();
                            },
                        });
                    }}
                >
Remove
                </Button>
            </div>
            <h4>Containers</h4>
            <div>
                <Button
                    variant="contained"
                    onClick={() => {
                        props.addWindows('Add container', AddContainer, { appInstanceId: instance.id, refetch });
                    }}
                >
Add container
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        props.addWindows('Add container', AddContainerWizard, { appInstanceId: instance.id, refetch });
                    }}
                >
Add container wizard
                </Button>
            </div>
            {instance.containers.map((container, _index) => (
                <div key={container.id || _index}>
                    <h4>{container.name}</h4>
                    <table>
                        <tr key="header">
                            <td>id</td>
                            <td>life_status</td>
                            <td>url</td>
                            <td>actions</td>
                        </tr>
                        <tr>
                            <td><Id id={container.id} /></td>
                            <td>{container.life_status}</td>
                            <td>
                                <a href={
                                    container.domain ? `https://${container.domain?.name}` : `http://${container.node.host}:${container.outer_port}`
                                }
                                >
                                    {
                                        container.domain ? `https://${container.domain?.name}` : `http://${container.node.host}:${container.outer_port}`
                                    }
                                </a>
                            </td>
                        </tr>
                    </table>
                    <div>
                        {
                            container.life_status === 'running' ? <Button
                                onClick={async () => {
                                    await stopContainer({ variables: { id: container.id } });
                                    refetch();
                                }}
                            >
Stop
                            </Button> : <Button
                                onClick={async () => {
                                    await startContainer({ variables: { id: container.id } });
                                    refetch();
                                }}
                            >
Start
                            </Button>
                        }
                        <Button
                            variant="contained"
                            onClick={() => {
                                props.addWindows('Edit container', EditContainer, {
                                    id: container.id,
                                    appInstanceId: instance.id,
                                    container,
                                    refetch,
                                });
                            }}
                        >
Edit container
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                props.addWindows('Explore', ContainerExplorer, {
                                    id: container.id,
                                });
                            }}
                        >
Explore
                        </Button>
                        <Button
                            onClick={async () => {
                                props.confirmDialog({
                                    title: 'Remove container',
                                    message: `Are you sure you want to remove container ${container.name}?`,
                                    onConfirm: async () => {
                                        await removeContainer({ variables: { id: container.id } });
                                        refetch();
                                    },
                                });
                            }}
                        >
Remove
                        </Button>
                    </div>
                    {/* <div>Drivers</div>
                    <div>
                        {data.getDevices.map(device => {
                            const containerDevice = container.devices?.find(_device => _device.device.id === device.id);
                            return <div key={device.id}>
                                {containerDevice && <div>
                                    <div>{containerDevice.device.name}</div>
                                    <div>
                                        {containerDevice.env_parameters?.map(env => <div>
                                            {env.key}
                                            {': '}
                                            {env.value}
                                        </div>)}
                                    </div>
                                    <div>
                                        {containerDevice.parameters?.map(param => <div>
                                            {param.key}
                                            {': '}
                                            {param.value}
                                        </div>)}
                                    </div>
                                    <div>
                                        <Button
                                            onClick={async () => {
                                                await removeDevice({ variables: { containerId: container.id, deviceId: containerDevice.device.id } });
                                                refetch();
                                            }}
                                        >
Remove
                                        </Button>
                                    </div>
                                </div>}
                                <DeviceFormNew
                                    container_id={container.id}
                                    device={device}
                                    update={!!containerDevice}
                                />
                            </div>;
                        })}
                    </div> */}
                    <div>Volumes</div>
                    <div>
                        {container.volumes?.map(containerVolume => {
                            const volume = data.getVolumes.find(_volume => _volume.id === containerVolume.volume_id);
                            return <div key={containerVolume.id}>
                                <div>{containerVolume.name}</div>
                                <table>
                                    <tr>
                                        <th>id</th>
                                        <td><Id id={containerVolume.id} /></td>
                                    </tr>
                                    <tr>
                                        <th>inner path</th>
                                        <td>{containerVolume.inner_path}</td>
                                    </tr>
                                    <tr>
                                        <th>volume name</th>
                                        <td>{volume?.name}</td>
                                    </tr>
                                </table>
                            </div>;
                        })}
                    </div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.addWindows('Edit volumes', ContainerVolumes, { id: container.id, instance, refetch });
                        }}
                    >
Edit volumes
                    </Button>
                    <div>Envs</div>
                    <div>
                        <table>
                            {container.envs?.map(containerEnv => <tr key={containerEnv.key}>
                                <td>{containerEnv.key}</td>
                                <td>{containerEnv.value.replace(/./g, '*')}</td>
                            </tr>)}
                            <EnvsDialog
                                refetch={refetch}
                                containerId={container.id}
                                envs={container.envs}
                                imageEnvs={container.image.envs}
                            />
                        </table>
                    </div>
                    <div>Actions</div>
                    <div>
                        {/* <Button
                            onClick={async () => {
                                await updateContainer({ variables: { id: container.id, noRebuild: false } });
                                refetch();
                            }}
                        >
Rebuild
                        </Button>
                        <Button
                            onClick={async () => {
                                await updateContainer({ variables: { id: container.id, noRebuild: true } });
                                refetch();
                            }}
                        >
Recreate
                        </Button> */}
                        <Button onClick={() => {
                            props.addWindows(`${container.name} log`, ContainerLog, { id: container.id });
                        }}
                        >
                            Show log
                        </Button>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Instance;
