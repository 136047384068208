/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { clone } from '../components/Utils';
import { ImageEnvRequirementTable, ImageEnvRequirementType } from '../components/tables';
import EnvField from '../components/EnvField';

interface ImageEnvsDialogProps {
    envs: ImageEnvRequirementTable[],
    imageId: string,
    refetch: () => void
}

const ImageEnvsDialog: React.FC<ImageEnvsDialogProps> = props => {
    const [open, setOpen] = useState(false);
    const [envs, setEnvs] = useState<Partial<ImageEnvRequirementTable>[]>([]);

    const [changeImageEnvs] = useMutation(gql`
        mutation($imageId: String!, $envs: [ImageEnvRequirementInput]!) {
            changeImageEnvs(imageId: $imageId envs: $envs)
        }
    `);

    useEffect(() => {
        setEnvs(props.envs);
    }, [open]);

    return <>
        <Button onClick={() => setOpen(true)}>Change envs</Button>
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
            <DialogTitle>Envs</DialogTitle>
            <DialogContent>
                {envs.map((env, i) => <div key={i} style={{ display: 'flex' }}>
                    <TextField
                        label="Name"
                        value={env.env_name}
                        onChange={e => {
                            const newEnvs = clone(envs);
                            newEnvs[i].env_name = e.target.value;
                            setEnvs(newEnvs);
                        }}
                        fullWidth
                        variant="standard"
                    />
                    <Select
                        value={env.type}
                        onChange={e => {
                            const newEnvs = clone(envs);
                            newEnvs[i].type = e.target.value as ImageEnvRequirementType;
                            if (newEnvs[i].type === ImageEnvRequirementType.Boolean) {
                                newEnvs[i].env_default_value = '';
                            }
                            if (newEnvs[i].type === ImageEnvRequirementType.Number) {
                                newEnvs[i].env_default_value = '0';
                            }
                            setEnvs(newEnvs);
                        }}
                        variant="standard"
                    >
                        {Object.values(ImageEnvRequirementType).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                    </Select>
                    <EnvField
                        onChange={value => {
                            const newEnvs = clone(envs);
                            newEnvs[i].env_default_value = value;
                            setEnvs(newEnvs);
                        }}
                        type={env.type}
                        value={env.env_default_value}
                        label="Default value"
                    />
                    <IconButton onClick={() => {
                        const newEnvs = clone(envs);
                        newEnvs.splice(i, 1);
                        setEnvs(newEnvs);
                    }}
                    >
                        <Delete />
                    </IconButton>
                </div>)}
                <Button onClick={() => {
                    const newEnvs = clone(envs);
                    newEnvs.push({
                        name: '',
                        env_name: '',
                        env_default_value: '',
                        type: ImageEnvRequirementType.String,
                    });
                    setEnvs(newEnvs);
                }}
                >
Add
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={async () => {
                    await changeImageEnvs({
                        variables: {
                            imageId: props.imageId,
                            envs: envs.map(env => ({
                                env_name: env.env_name,
                                name: env.name,
                                env_default_value: env.env_default_value,
                                type: env.type,
                            })),
                        },
                    });
                    props.refetch();
                    setOpen(false);
                }}
                >
OK
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default ImageEnvsDialog;
