import {
    Button, FormControl, IconButton, InputLabel, MenuItem, Select, Step, StepButton, StepLabel, Stepper,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { WindowProps } from '../../App';
import { ContainerTable, ContainerVolumeTable, ImageEnvRequirementType } from '../../components/tables';
import { clone } from '../../components/Utils';
import EnvField from '../../components/EnvField';

interface AddContainerWizardProps {
    instanceId: string;
}

const AddContainerWizard: WindowProps<AddContainerWizardProps> = props => {
    const relation = useQuery(gql`
        query {
            getDomains {
                id
                name
            }
            getNodes {
                id
                name
            }
            getImages {
                id
                name
                envs {
                    id
                    env_name
                    env_default_value
                    type
                }
            }
            getVolumes {
                id
                name
            }
            getContainers {
                id
                name
                envs {
                    key
                    value
                }
            }
        }
    `);
    const [activeStep, setActiveStep] = useState(0);
    const [container, setContainer] = useState<{
        container: Partial<ContainerTable>,
        envs: { key: string, value: string }[],
        volumes: Partial<ContainerVolumeTable>[]
    }>({
        container: {
            name: '',
            outer_port: 0,
            node_id: '',
            domain_id: '',
            app_instance_id: props.instanceId,
            image_id: '',
        },
        envs: [],
        volumes: [],
    });

    const [selectedContainer, setSelectedContainer] = useState('');

    const changeData = (func: (_container: typeof container) => void) => {
        const _container = clone(container);
        func(_container);
        setContainer(_container);
    };

    if (!(relation.data)) {
        return <div>Loading...</div>;
    }

    const imageEnvs = relation.data.getImages.find(image => image.id === container.container.image_id)?.envs;

    const nameStep = <div>
        <h2>Name</h2>
        <div>
            <TextField
                label="Name"
                value={container.container.name}
                onChange={e => changeData(_container => _container.container.name = e.target.value)}
                variant="standard"
            />
        </div>
    </div>;
    const nodeStep = <div>
        <h2>Node</h2>
        <FormControl fullWidth>
            <InputLabel id="node">Node</InputLabel>
            <Select
                labelId="node"
                value={container.container.node_id}
                onChange={e => changeData(_container => _container.container.node_id = e.target.value)}
                variant="standard"
            >
                {relation.data.getNodes.map(node => <MenuItem key={node.id} value={node.id}>{node.name}</MenuItem>)}
            </Select>
        </FormControl>
    </div>;
    const imageStep = <div>
        <h2>Image</h2>
        <FormControl fullWidth>
            <InputLabel id="image">Image</InputLabel>
            <Select
                labelId="image"
                value={container.container.image_id}
                onChange={e => changeData(_container => _container.container.image_id = e.target.value)}
                variant="standard"
            >
                {relation.data.getImages.map(image => <MenuItem key={image.id} value={image.id}>{image.name}</MenuItem>)}
            </Select>
        </FormControl>
    </div>;
    const domainStep = <div>
        <h2>Domain</h2>
        <FormControl fullWidth>
            <InputLabel id="domain">Domain</InputLabel>
            <Select
                labelId="domain"
                value={container.container.domain_id}
                onChange={e => changeData(_container => _container.container.domain_id = e.target.value)}
                variant="standard"
            >
                <MenuItem value={null}>None</MenuItem>
                {relation.data.getDomains.map(domain => <MenuItem key={domain.id} value={domain.id}>{domain.name}</MenuItem>)}
            </Select>
        </FormControl>
    </div>;
    const envsStep = <div>
        <h2>Envs</h2>
        <div>
            <Select value={selectedContainer} onChange={e => setSelectedContainer(e.target.value)}>
                {relation.data.getContainers.map(container => <MenuItem key={container.id} value={container.id}>{container.name}</MenuItem>)}
            </Select>
            <Button onClick={() => {
                const container = relation.data.getContainers.find(_container => _container.id === selectedContainer);
                changeData(_container => {
                    _container.envs = container.envs;
                });
            }}
            >
                        Copy from container
            </Button>
        </div>
        {container.envs.map((env, i) => {
            const imageEnv = imageEnvs?.find(imageEnv => imageEnv.env_name === env.key);
            return <div key={i} style={{ display: 'flex' }}>
                <TextField
                    label="Name"
                    value={env.key}
                    onChange={e => {
                        changeData(_container => {
                            _container.envs[i].key = e.target.value;
                        });
                    }}
                    fullWidth
                    variant="standard"
                />
                {imageEnv ? '*' : ''}
                <EnvField
                    onChange={value => {
                        changeData(_container => {
                            _container.envs[i].value = value;
                        });
                    }}
                    type={imageEnv?.type || ImageEnvRequirementType.String}
                    value={env.value}
                    label="Value"
                />
                <IconButton onClick={() => {
                    changeData(_container => {
                        _container.envs.splice(i, 1);
                    });
                }}
                >
                    <Delete />
                </IconButton>
            </div>;
        })}
        {imageEnvs?.filter(imageEnv => !container.envs.find(env => env.key === imageEnv.env_name)).map((imageEnv, i) =>
            <div key={`image${i}`}>
                <Button
                    onClick={() => {
                        changeData(_container => {
                            _container.envs.push({ key: imageEnv.env_name, value: imageEnv.env_default_value });
                        });
                    }}
                    variant="contained"
                >
                        Add env
                    {' '}
                    {imageEnv.env_name}
                </Button>
            </div>)}
        <Button onClick={() => {
            changeData(_container => {
                _container.envs.push({ key: '', value: '' });
            });
        }}
        >
Add
        </Button>
    </div>;
    const volumeStep = <div>
        <h2>Volume</h2>
        {
            container.volumes.map((volume, i) => <div key={i} style={{ display: 'flex' }}>
                <TextField
                    label="Name"
                    value={volume.name}
                    onChange={e => {
                        changeData(_container => {
                            _container.volumes[i].name = e.target.value;
                        });
                    }}
                    fullWidth
                    variant="standard"
                />
                <TextField
                    label="Inner path"
                    value={volume.inner_path}
                    onChange={e => {
                        changeData(_container => {
                            _container.volumes[i].inner_path = e.target.value;
                        });
                    }}
                    fullWidth
                    variant="standard"
                />
                <FormControl fullWidth>
                    <InputLabel id="volume">Volume</InputLabel>
                    <Select
                        labelId="volume"
                        value={volume.volume_id}
                        onChange={e => {
                            changeData(_container => {
                                _container.volumes[i].volume_id = e.target.value as string;
                            });
                        }}
                        variant="standard"
                    >
                        {relation.data.getVolumes.map(volume => <MenuItem key={volume.id} value={volume.id}>{volume.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <IconButton onClick={() => {
                    changeData(_container => {
                        _container.volumes.splice(i, 1);
                    });
                }}
                >
                    <Delete />
                </IconButton>
            </div>)
        }
        <Button
            onClick={() => {
                changeData(_container => {
                    _container.volumes.push({ name: '', inner_path: '' });
                });
            }}
            variant="contained"
        >
            Add volume
        </Button>
    </div>;

    const steps: {component: React.JSX.Element, name: string}[] = [
        { component: nameStep, name: 'Name' },
        { component: nodeStep, name: 'Node' },
        { component: imageStep, name: 'Image' },
        { component: domainStep, name: 'Domain' },
        { component: envsStep, name: 'Envs' },
        { component: volumeStep, name: 'Volume' },
    ];

    return <div>
        <Stepper nonLinear activeStep={activeStep}>
            {steps.map((step, index) => <Step key={step.name}>
                <StepButton onClick={() => setActiveStep(index)}>{step.name}</StepButton>
            </Step>)}
        </Stepper>
        <div>
            <Button
                disabled={activeStep === 0}
                onClick={() => setActiveStep(activeStep - 1)}
            >
Back
            </Button>
            <Button
                disabled={activeStep === steps.length - 1}
                onClick={() => setActiveStep(activeStep + 1)}
            >
Next
            </Button>
        </div>
        {steps[activeStep].component}
    </div>;
};

export default AddContainerWizard;
